import Button from "src/components/Button";
import Modal from "src/components/Modal";
import { setUnlockClueModal } from "src/store/actions/clue";
import { useReducerData, useStoreActions } from "src/store/hooks";
import "./modal.styles.scss";

const UnlockedClueModal = () => {
  const isOpen = useReducerData("clue", "isOpenUnlockClueModal", false);
  const action = useStoreActions({ setUnlockClueModal });

  const onToggle = () => {
    action.setUnlockClueModal(!isOpen);
  };
  return (
    <Modal color="primary" isOpen={isOpen} toggle={onToggle}>
      <section className="container">
        <h2 className="title">Congratulations on Unlocking a Clue!</h2>
        <p className="description">
          {`Your Treasure Hunting efforts are paying off! 
          Every discovery brings you nearer to the treasure.`}
        </p>
        <Button
          buttonText="Continue to the clue"
          buttonColor="primary"
          onClick={onToggle}
          size="lg"
          textSize="lg"
        />
      </section>
    </Modal>
  );
};

export default UnlockedClueModal;
