import cx from "classnames";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as ActiveCompleteIcon } from "src/assets/icons/active-complete.svg";
import { ReactComponent as CheckIcon } from "src/assets/icons/check-rounded-icon.svg";
import { ReactComponent as LockIcon } from "src/assets/icons/lock-icon.svg";
import { ReactComponent as PendingIcon } from "src/assets/icons/purple-outlined-circle-icon.svg";
import { ReactComponent as PendingNextIcon } from "src/assets/icons/white-outlined-circle-icon.svg";
import Typography from "src/components/Typography";
import { getQuestionIcon } from "src/pages/Play/Clue/getIcon";
import { ClueProps } from "src/pages/Play/Clue/types";
import classes from "./styles.module.scss";

type ClueListItemProps = {
  clue: ClueProps;
  foundLamps?: boolean;
  handleSelectClue: (clue: ClueProps) => void;
  clueIndex: number;
};

const ClueListItem: React.FC<ClueListItemProps> = ({
  clue,
  handleSelectClue,
  foundLamps,
  clueIndex,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getProgressIcon = () => {
    switch (clue.answer_status) {
      case "complete":
        if (clue.is_current) {
          return (
            <ActiveCompleteIcon
              style={{ background: "#121429", height: 30, width: 30 }}
            />
          );
        }
        return <CheckIcon />;
      case "locked":
        return (
          <LockIcon
            style={{
              height: 30,
              width: 30,
            }}
          />
        );
      case "not-viewed":
      case "viewed":
      case "invalid":
        if (clue.is_current) {
          return (
            <PendingIcon
              style={{ background: "#121429", height: 30, width: 30 }}
            />
          );
        }
        return (
          <div
            style={{
              background: "#121429",
              borderRadius: "50%",
              border: "2px solid white",
              height: 30,
              width: 30,
            }}
          />
        );
      default:
        return (
          <PendingNextIcon
            style={{ background: "#121429", height: 30, width: 30 }}
          />
        );
    }
  };

  const onSelectClue = async () => {
    if (clue.answer_status === "locked") {
      searchParams.set("show_upgrade_modal", "true");
      setSearchParams(searchParams);
      return;
    }
    handleSelectClue(clue);
  };

  return (
    <div
      className={cx(classes.itemWrapper, classes[clue.answer_status], {
        [classes.allLampFound]: foundLamps,
      })}
    >
      <div className={classes.itemFlex}>
        <div className={classes.itemProcess}>
          <div className={classes.itemLine}>{getProgressIcon()}</div>
        </div>
        <div
          onClick={onSelectClue}
          className={cx(classes.itemInfo, classes[clue.answer_status], {
            [classes.allLampFound]: foundLamps,
            [classes.active]: clue.is_current,
          })}
        >
          <div className={classes.itemInfoText}>
            <Typography
              variant="p14"
              fontWeight="medium"
              className={cx("mb-0", classes[clue.answer_status], {
                [classes.active]: !!clue.is_current,
              })}
            >
              {clue.locked === 1
                ? `WL$ Unlocked Clue # ${clueIndex}`
                : `WL$  Clue # ${clue.clue_number}`}
            </Typography>
            {clue.answer_status === "not-viewed" && (
              <div className={classes.newBadge}>New!</div>
            )}
          </div>
          <div
            className={cx(classes.itemIcon, {
              [classes.allLampFoundIcon]: foundLamps,
            })}
          >
            {getQuestionIcon({
              type: clue?.field_type?.slug,
              height: 20,
              width: 20,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClueListItem;
