/* eslint-disable max-lines */
import { Reducer } from "redux";

import { handleData } from "../middlewares/handleData";
import Types from "../types/clue";

const initialState = {
  clueModal: {
    isOpen: false,
    data: {},
  },
  purchaseModal: {
    isOpen: false,
    index: null,
  },
  season: {
    id: 1,
  },
  clues: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  postAnswer: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  editClueAnswer: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  editClueStatus: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  clueData: {
    currentClue: {},
  },
  clueTypes: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  seasonList: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  interactiveWordPuzzleState: {
    answer: "",
    error: "",
  },
  purchaseClueHint: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  purchaseHintList: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  advantageCluesPurchaseHistoryData: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  purchaseRangeFinderAdvantageClue: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  purchaseTreasureViewsAdvantageClue: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  rangeFinderBuyOptions: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  treasureViewsBuyOptions: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  unlockedClueByQr: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  unlockedClues: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
};

const ClueReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.CLUE_MODAL_TOGGLE:
      return {
        ...state,
        clueModal: {
          ...state.clueModal,
          isOpen: payload.isOpen,
          data: payload.data,
        },
      };
    case Types.PURCHASE_MODAL_TOGGLE:
      return {
        ...state,
        purchaseModal: {
          ...state.purchaseModal,
          isOpen: payload.isOpen,
          index: payload.index,
        },
      };
    case Types.SET_SEASON_INFO:
      return {
        ...state,
        season: {
          ...state.season,
          id: payload?.data?.id,
        },
      };
    case Types.SET_CLUE_DATA:
      return {
        ...state,
        clueData: {
          ...state.clueData,
          currentClue: payload.currentClue,
        },
      };
    case Types.FETCH_CLUES_STARTED:
      return {
        ...state,
        clues: {
          ...state.clues,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.FETCH_CLUES_SUCCESS:
      return {
        ...state,
        clues: {
          ...state.clues,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.FETCH_CLUES_FAILED:
      return {
        ...state,
        clues: {
          ...state.clues,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.PURCHASE_CLUE_HINT_STARTED:
      return {
        ...state,
        purchaseClueHint: {
          ...state.purchaseClueHint,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.PURCHASE_CLUE_HINT_SUCCESS:
      return {
        ...state,
        purchaseClueHint: {
          ...state.purchaseClueHint,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.PURCHASE_CLUE_HINT_FAILED:
      return {
        ...state,
        purchaseClueHint: {
          ...state.purchaseClueHint,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };

    case Types.FETCH_PURCHASE_HINT_LIST_STARTED:
      return {
        ...state,
        purchaseHintList: {
          ...state.purchaseHintList,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.FETCH_PURCHASE_HINT_LIST_SUCCESS:
      return {
        ...state,
        purchaseHintList: {
          ...state.purchaseHintList,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.FETCH_PURCHASE_HINT_LIST_FAILED:
      return {
        ...state,
        purchaseHintList: {
          ...state.purchaseHintList,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };

    case Types.POST_CLUE_ANSWER_STARTED:
      return {
        ...state,
        postAnswer: {
          ...state.postAnswer,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.POST_CLUE_ANSWER_SUCCESS:
      return {
        ...state,
        postAnswer: {
          ...state.postAnswer,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.POST_CLUE_ANSWER_FAILED:
      return {
        ...state,
        postAnswer: {
          ...state.postAnswer,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.EDIT_CLUE_ANSWER_STARTED:
      return {
        ...state,
        editClueAnswer: {
          ...state.editClueAnswer,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.EDIT_CLUE_ANSWER_SUCCESS:
      return {
        ...state,
        editClueAnswer: {
          ...state.editClueAnswer,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.EDIT_CLUE_ANSWER_FAILED:
      return {
        ...state,
        editClueAnswer: {
          ...state.editClueAnswer,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.EDIT_CLUE_STATUS_STARTED:
      return {
        ...state,
        editClueStatus: {
          ...state.editClueStatus,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.EDIT_CLUE_STATUS_SUCCESS:
      return {
        ...state,
        editClueStatus: {
          ...state.editClueStatus,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.EDIT_CLUE_STATUS_FAILED:
      return {
        ...state,
        editClueStatus: {
          ...state.editClueStatus,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.FETCH_CLUE_TYPES_STARTED:
      return {
        ...state,
        clueTypes: {
          ...state.clueTypes,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.FETCH_CLUE_TYPES_SUCCESS:
      return {
        ...state,
        clueTypes: {
          ...state.clueTypes,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.FETCH_CLUE_TYPES_FAILED:
      return {
        ...state,
        clueTypes: {
          ...state.clueTypes,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.FETCH_SEASONS_STARTED:
      return {
        ...state,
        seasonList: {
          ...state.seasonList,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.FETCH_SEASONS_SUCCESS:
      return {
        ...state,
        seasonList: {
          ...state.seasonList,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.FETCH_SEASONS_FAILED:
      return {
        ...state,
        seasonList: {
          ...state.seasonList,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.INTERACTIVE_WORD_PUZZLE_STATE_UPDATE:
      return {
        ...state,
        interactiveWordPuzzleState: {
          ...state.interactiveWordPuzzleState,
          answer: payload.answer,
          error: payload.error,
        },
      };
    case Types.GET_ADVANTAGE_CLUES_PURCHASE_HISTORY:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          advantageCluesPurchaseHistoryData: {
            ...prevState.advantageCluesPurchaseHistoryData,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          advantageCluesPurchaseHistoryData: {
            loading: false,
            data: payload.data || [],
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          advantageCluesPurchaseHistoryData: {
            loading: false,
            error: payload,
          },
        }),
      });
    case Types.PURCHASE_RANGE_FINDER_ADVANTAGE_CLUE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          purchaseRangeFinderAdvantageClue: {
            ...prevState.purchaseRangeFinderAdvantageClue,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          purchaseRangeFinderAdvantageClue: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          purchaseRangeFinderAdvantageClue: {
            loading: false,
            error: payload,
          },
        }),
      });
    case Types.PURCHASE_TREASURE_VIEWS_ADVANTAGE_CLUE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          purchaseTreasureViewsAdvantageClue: {
            ...prevState.purchaseTreasureViewsAdvantageClue,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          purchaseTreasureViewsAdvantageClue: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          purchaseTreasureViewsAdvantageClue: {
            loading: false,
            error: payload,
          },
        }),
      });
    case Types.RANGE_FINDER_BUY_OPTIONS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          rangeFinderBuyOptions: {
            ...prevState.rangeFinderBuyOptions,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          rangeFinderBuyOptions: {
            loading: false,
            data: payload.data || [],
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          rangeFinderBuyOptions: {
            loading: false,
            error: payload,
          },
        }),
      });
    case Types.TREASURE_VIEWS_BUY_OPTIONS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          treasureViewsBuyOptions: {
            ...prevState.treasureViewsBuyOptions,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          treasureViewsBuyOptions: {
            loading: false,
            data: payload.data || [],
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          treasureViewsBuyOptions: {
            loading: false,
            error: payload,
          },
        }),
      });
    case Types.RESET_ADVANTAGE_CLUES_DATA:
      return {
        ...state,
        treasureViewImages: {
          ...state.treasureViewImages,
          data: [],
        },
        rangeFinderBuyOptions: {
          ...state.rangeFinderBuyOptions,
          data: [],
        },
        advantageCluesPurchaseHistoryData: {
          ...state.advantageCluesPurchaseHistoryData,
          data: [],
        },
        treasureViewsBuyOptions: {
          ...state.treasureViewsBuyOptions,
          data: [],
        },
      };
    case Types.GET_UNLOCKED_CLUE_BY_QR:
      return {
        ...state,
        unlockedClueByQr: {
          ...state.unlockedClueByQr,
          loading: true,
        },
      };
    case Types.GET_UNLOCKED_CLUE_BY_QR_SUCCESS:
      return {
        ...state,
        unlockedClueByQr: {
          ...state.unlockedClueByQr,
          data: payload.data,
          loading: false,
        },
      };
    case Types.GET_UNLOCKED_CLUE_BY_QR_FAILED:
      return {
        ...state,
        unlockedClueByQr: {
          ...state.unlockedClueByQr,
          error: payload.message,
          loading: false,
        },
      };
    case Types.GET_UNLOCKED_CLUES_STARTED:
      return {
        ...state,
        unlockedClues: {
          ...state.unlockedClues,
          loading: true,
        },
      };
    case Types.GET_UNLOCKED_CLUES_SUCCESS:
      return {
        ...state,
        unlockedClues: {
          ...state.unlockedClues,
          data: payload.data,
          loading: false,
        },
      };
    case Types.GET_UNLOCKED_CLUES_FAILED:
      return {
        ...state,
        unlockedClues: {
          ...state.unlockedClues,
          error: payload.message,
          loading: false,
        },
      };
    case Types.SHOW_UNLOCK_CLUE_MODAL:
      return {
        ...state,
        isOpenUnlockClueModal: payload,
      };
    case Types.SET_LAMP_ID:
      return {
        ...state,
        lampId: payload.lampId,
      };
    default:
      return state;
  }
};

export default ClueReducer;
