import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";

import { useIsUserProfileFreeTrial } from "src/helpers/hooks/useUserProfile";
import { editClueStatus, setUnlockClueModal } from "src/store/actions/clue";
import { useStoreActions } from "src/store/hooks";
import { useClueActions } from "src/utils/useActions";
import { useCluesData } from "src/utils/useData";
import { ClueProps } from "../Play/Clue/types";
import { getClueStatus } from "../Play/Clue/utils";
import "./qr.scss";

const useGetUnlockedClueByQr = (qrId: string) => {
  const actions = useStoreActions({ editClueStatus, setUnlockClueModal });
  const { getUnlockedClueByQr, getUnlockedClues, clearUnlockedClues } =
    useClueActions();
  const [isDataReady, setIsDataReady] = useState(false);
  const [qrStatus, setQrStatus] = useState("");
  const {
    getUnlockedClueByQrData: { data, loading },
    getUnlockedCluesData: { data: unlockedCluesData },
  } = useCluesData();
  const isFreeTrial = useIsUserProfileFreeTrial();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQr = async () => {
      try {
        clearUnlockedClues();
        const res: any = await getUnlockedClueByQr(qrId);
        // eslint-disable-next-line no-console
        console.log(res);
        if (res.status === 0) {
          setQrStatus("QR not found");
        } else {
          setIsDataReady(true);
          setQrStatus("Success");
        }
      } catch (e) {
        setQrStatus("QR not found");
      }
    };
    fetchQr();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrId]);

  const fetchUnlockedClues = async () => {
    const response = await getUnlockedClues(data.lamp_id);
    return response;
  };

  const handleClueDetail = async (clue: ClueProps | undefined) => {
    const updatedStatus = [
      {
        id: clue?.id || 0,
        is_current: true,
        status: getClueStatus(clue?.answer_status || "not-viewed"),
        locked: clue?.locked || 1,
      },
    ];

    await actions.editClueStatus(
      updatedStatus,
      clue?.locked === 1 ? true : false
    );

    actions.setUnlockClueModal(true);

    navigate(`/play/clues?region=${data.region.id}&season=${data.season_id}`, {
      replace: true,
      state: {
        tab: isFreeTrial ? "clues" : "unlocked-clues",
      },
    });
  };

  useEffect(() => {
    const onFetchUnlockedClues = async () => {
      if (data?.intent === "locked_clue" && isDataReady) {
        await fetchUnlockedClues();
        const clue = unlockedCluesData.find((clue) => clue.id === data.clue_id);
        await handleClueDetail(clue);
      }
    };

    if (data && unlockedCluesData) {
      onFetchUnlockedClues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unlockedCluesData, data, isDataReady]);

  return { loading, qrStatus };
};

const QrPage = () => {
  const { qrId } = useParams();

  const { loading, qrStatus } = useGetUnlockedClueByQr(qrId || "");

  if (loading) {
    return (
      <section className="container">
        <Spinner center color="white" size={128} />
      </section>
    );
  }

  return (
    <section className="container">
      <h1>{qrStatus}</h1>
    </section>
  );
};

export default QrPage;
