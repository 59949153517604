import cx from "classnames";
import React from "react";
import { ReactComponent as LeftArrowIcon } from "src/assets/icons/left-arrow-icon.svg";
import { ReactComponent as RightArrowIcon } from "src/assets/icons/right-arrow-icon.svg";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import { ClueProps } from "src/pages/Play/Clue/types";

import ClueListItem from "./ClueListItem";
import classes from "./styles.module.scss";

type ClueListProps = {
  currentClue: number | null;
  handleSelectClue: (clue: ClueProps) => void;
  clues: ClueProps[];
  allFound: boolean;
};

const ClueList: React.FC<ClueListProps> = ({
  handleSelectClue,
  currentClue,
  clues,
  allFound,
}) => {
  const hideSubscribeMessage = true;
  const clueIndex = clues.findIndex((c) => c.id === currentClue);

  const isNextDisable =
    clueIndex === -1 ||
    clueIndex === clues.length - 1 ||
    clues[clueIndex + 1]?.answer_status === "locked";

  const isPreviousDisable =
    clueIndex === -1 ||
    clueIndex === 0 ||
    clues[clueIndex - 1]?.answer_status === "locked";

  return (
    <div>
      <div className={classes.clueListContainer}>
        <div
          className={cx(classes.clueListWrapper, {
            // [classes.disable]: allFound,
          })}
        >
          {clues.map((data, index) => {
            return (
              <ClueListItem
                foundLamps={allFound}
                key={data.id}
                clue={data}
                clueIndex={index + 1}
                handleSelectClue={handleSelectClue}
              />
            );
          })}
        </div>
        {allFound && !hideSubscribeMessage && (
          <div className={classes.allLampFound}>
            <Typography variant="p20">All lamps have been found.</Typography>
            <Typography className="mb-5" variant="p20">
              Next season starts on 00/00/0000 for your next chance to win.
            </Typography>
            <div className={classes.action}>
              <Button
                onClick={async () => {}}
                buttonClassName={classes.button}
                buttonText={"Subscribe for 2025"}
                buttonColor="secondary"
              />
            </div>
          </div>
        )}
        <div
          className={cx(classes.clueActions, { [classes.disable]: allFound })}
        >
          <Button
            onClick={() => {
              clueIndex !== -1 && handleSelectClue(clues[clueIndex - 1]);
            }}
            leftIcon={<LeftArrowIcon />}
            buttonText="Previous Clue"
            disabled={isPreviousDisable}
          />
          <div className={classes.divider}></div>
          <Button
            onClick={() => {
              clueIndex !== -1 && handleSelectClue(clues[clueIndex + 1]);
            }}
            rightIcon={<RightArrowIcon />}
            buttonText="Next Clue"
            disabled={isNextDisable}
          />
        </div>
      </div>
    </div>
  );
};

export default ClueList;
